<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" />
      <PageCompressImage />
    </div>

    <div v-else class="h-full">
      <LayoutDefault :max-upload="maxUpload" @finished="onFinished">
        <template #aside>
          <div class="flex flex-col space-y-6 mt-8">
            <div class="flex space-x-2">
              <input
                id="convertToWebp"
                v-model="featureStore.options[slug].convertToWebp"
                class="checkbox"
                type="checkbox"
              />
              <label for="convertToWebp" class="input-label">Convert to WEBP</label>
            </div>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/useFeatureSetup'
import PageCompressImage from '&/landing_pages/PageCompressImage.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const maxUpload = 10

const { slug, ready, router, featureStore, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'CompressImageResult' })
}
</script>
